export const localAreas = [
  {
    state: "Eastern Cape",
    alias: "eastern_cape",
    lgas: [
      "Alfred Nzo District",
      "Amathole District",
      "Buffalo City Metropolitan Municipality",
      "Chris Hani District",
      "Joe Gqabi District",
      "Nelson Mandela Bay Metropolitan Municipality",
      "OR Tambo District",
      "Sarah Baartman District",
    ],
  },
  {
    state: "Free State",
    alias: "free_state",
    lgas: [
      "Fezile Dabi",
      "Mangaung Metropolitan Municipality",
      "Lejweleputswa",
      "Thabo Mofutsanyana",
      "Xhariep",
    ],
  },
  {
    state: "Gauteng",
    alias: "gauteng",
    lgas: [
      "City of Ekurhuleni Metropolitan Municipality",
      "Sedibeng",
      "West Rand",
      "Tshwane Metropolitan Municipality",
      "Johannesburg Metropolitan Municipality",
    ],
  },
  {
    state: "KwaZulu-Natal",
    alias: "kwazulu_natal",
    lgas: [
      "Amajuba",
      "eThekwini Metropolitan Municipality",
      "Harry Gwala",
      "iLembe",
      "King Cetshwayo",
      "Ugu",
      "uMgungundlovu",
      "Umkhanyakude",
      "Umzinyathi",
      "Uthukela",
      "Zululand",
    ],
  },
  {
    state: "Limpopo",
    alias: "limpopo",
    lgas: ["Capricorn", "Mopani", "Sekhukhune", "Vhembe", "Waterberg"],
  },
  {
    state: "Mpumalanga",
    alias: "mpumalanga",
    lgas: ["Ehlanzeni", "Gert Sibande", "Nkangala"],
  },
  {
    state: "North West",
    alias: "north_west",
    lgas: [
      "Bojanala",
      "Dr Kenneth Kaunda",
      "Ngaka Modiri Molema",
      "Dr Ruth Segomotsi Mompati",
    ],
  },
  {
    state: "Northern Cape",
    alias: "northern_cape",
    lgas: [
      "Frances Baard",
      "John Taolo Gaetsewe",
      "Namakwa",
      "Pixley ka Seme",
      "ZF Mgcawu",
    ],
  },
  {
    state: "Western Cape",
    alias: "western_cape",
    lgas: [
      "Cape Winelands",
      "Central Karoo",
      "City of Cape Town Metropolitan Municipality",
      "Garden Route",
      "Overberg",
      "West Coast",
    ],
  },
];
