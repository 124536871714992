import React, { Fragment, useEffect, useState } from "react";

import { func, bool } from "prop-types";
import styled from "styled-components";

import {
  TopHeader,
  RippleButton,
  UserAvatar,
  InfoBadge,
} from "../../../../../../components";
import { Close } from "../../../../../../containers/HeaderContainer";
import {
  ScreenContainer,
  FlexCenteredBlock,
  FlexContainer,
} from "../../../../../../containers/ScreenContainer";
import { colors } from "../../../../../../styles";
import UserImage from "../../../assets/default_user.svg";
import ShopperImage from "../../../assets/shopper.svg";
import { Space } from "../../../styles";

const Padding = styled.div`
  padding: 0 1em;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.boldDark};
  margin-top: 14px;
`;

const Subtitle = styled.div`
  font-size: 14px;
  color: ${colors.lightDark};
  line-height: 21px;
  text-align: center;
  margin-top: 8px;
`;

const CardDetailsWrapper = styled.div`
  position: relative;
  display: flex;
  min-height: 180px;
  min-width: 343px;
  background-color: ${colors.gray4};
  border-radius: 8px;
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 20px;

  &::after {
    content: url(${ShopperImage});
    position: absolute;
    bottom: -4px;
    right: 0px;
  }
`;

const CardUserName = styled.span`
  position: absolute;
  color: ${colors.bgBlue};
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-right: 10px;
  left: 16px;
  top: 20px;
`;

const CardAddressHolder = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 16px;
  top: 48px;
`;

const CardAddress = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: ${colors.lightDark};
`;

const CardPhoneNumber = styled.p`
  position: absolute;
  color: ${colors.boldDark};
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  left: 16px;
  bottom: 0px;
`;

const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 375px;
  width: inherit;
  padding: 15px 15px 40px;
`;

const deliveryLocation =
  localStorage.getItem("deliveryLocation") &&
  JSON.parse(localStorage.getItem("deliveryLocation"));
const { state: deliveryState } = deliveryLocation || {};

const checkRequiredFields = (address, lga, phoneNumber, state, city) => {
  return (
    !address || !lga || !phoneNumber || !state || !checkDeliveryLocation(state)
  );
};

const checkDeliveryLocation = (state) => {
  return String(deliveryState).toLowerCase() === String(state).toLowerCase();
};

export const MerchantFound = ({
  open,
  cancel,
  sabiUser,
  setSabiUserEdit,
  setSabiUserUse,
}) => {
  const [requireEdit, setRequireEdit] = useState(false);

  useEffect(() => {
    if (sabiUser) {
      const edit = checkRequiredFields(
        sabiUser?.streetAddress,
        sabiUser?.lga,
        sabiUser?.phoneNumber,
        sabiUser?.state,
        sabiUser?.city
      );
      setRequireEdit(edit);
    }
  }, [sabiUser]);

  return (
    open && (
      <Fragment>
        <TopHeader title={"Merchant Found"} withSpacesHeader>
          <Close left={"16px"} onClick={cancel} />
        </TopHeader>

        <ScreenContainer top="0" padding="0">
          <Space height="90px" />
          <Padding>
            <FlexCenteredBlock>
              <UserAvatar
                avatar={UserImage}
                width="72px"
                height="72px"
                borderRadius={"50%"}
              />
              <Title>We found a Sabi Merchant</Title>
              <Subtitle>
                The phone number you provided belongs to a sabi Merchant
              </Subtitle>
            </FlexCenteredBlock>

            <CardDetailsWrapper>
              <CardUserName>{`${sabiUser.firstName || ""} ${
                sabiUser.lastName || ""
              }`}</CardUserName>
              <CardAddressHolder>
                <CardAddress>{`${sabiUser.shopNumber || ""} ${
                  sabiUser.streetAddress || ""
                }`}</CardAddress>
                {sabiUser?.lga && (
                  <CardAddress>{`${sabiUser.lga} Municipalities`}</CardAddress>
                )}
                {sabiUser?.state && (
                  <CardAddress>{`${sabiUser.state} Province`}</CardAddress>
                )}
              </CardAddressHolder>
              <CardPhoneNumber>{sabiUser.phoneNumber}</CardPhoneNumber>
            </CardDetailsWrapper>

            {requireEdit && (
              <InfoBadge
                margin={"16px 0px"}
                title={
                  !sabiUser?.state ? "Address is incomplete" : "Address error"
                }
                message={
                  !sabiUser?.state
                    ? "Fill in the complete address to continue."
                    : "The user's county does not match your shopping location."
                }
              />
            )}

            <ButtonWrapper>
              {requireEdit && (
                <RippleButton
                  onClick={() => {
                    setSabiUserEdit && setSabiUserEdit(sabiUser);
                  }}
                >
                  Edit Address
                </RippleButton>
              )}

              {!requireEdit && (
                <FlexContainer>
                  <RippleButton
                    backgroundColor="#FFFFFF"
                    color="#718596"
                    border="1px solid #718596"
                    margin="32px 4px 0 0"
                    onClick={() => {
                      setSabiUserEdit && setSabiUserEdit(sabiUser);
                    }}
                  >
                    Edit Address
                  </RippleButton>
                  <RippleButton
                    margin="32px 0 0 4px"
                    onClick={() => {
                      setSabiUserUse && setSabiUserUse(sabiUser);
                    }}
                  >
                    Use Address
                  </RippleButton>
                </FlexContainer>
              )}
            </ButtonWrapper>
          </Padding>
        </ScreenContainer>
      </Fragment>
    )
  );
};

MerchantFound.propTypes = {
  open: bool,
  cancel: func,
};
